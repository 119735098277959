import './TimelineCopyModal.scss';
import React, { useState } from 'react';
import { Template } from '../../Model/Template';
import { useStateSelector } from '../../../../store/selectors';
import Rodal from '../../../../components/Rodal/Rodal';
import * as yup from 'yup';
import { Form, Field } from '@availity/form';
import createNotification from '../../../../utils/createNotification';
import Loader from 'react-loaders';

export interface TimelineCopyModalProps {
    template: Template;
    onClose: () => void;
    fetchData: () => void;
    isVisible: boolean;
}

interface CopyTemplateFormValues {
    newName: string;
}

const TimelineCopyModal = (props: TimelineCopyModalProps) => {
    const axios = useStateSelector((state) => state.core.axios);

    const [isLoading, setIsLoading] = useState(false);

    const [existingNames, setExistingNames] = useState([props.template.name]);

    const makeTimeineCopy = (newName: string) => {
        axios
            .post(
                `api/Marketplace/TimelineTemplates/${props.template.id}/copy`,
                {
                    name: newName,
                }
            )
            .then((response: any) => {
                if (response) {
                    if (response.status === 200) {
                        createNotification(
                            'Timeline Template copied successfully',
                            'success'
                        );
                        props.onClose();
                        props.fetchData();
                    } else if (
                        response.response &&
                        response.response.status === 409
                    ) {
                        setExistingNames([...existingNames, newName]);
                        createNotification(
                            'Timeline Template with such name already exists.',
                            'error'
                        );
                    } else {
                        createNotification(
                            'An unexpected error occurred while copying Timeline Template.',
                            'error'
                        );
                    }
                }
            })
            .catch(() => {
                createNotification(
                    'An unexpected error occurred while copying Timeline Template.',
                    'error'
                );
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const renderForm = () => (
        <Form
            initialValues={{
                newName: `${props.template.name} - Copy`,
            }}
            validationSchema={yup.object().shape({
                newName: yup
                    .string()
                    .required('Template copy name is required')
                    .notOneOf(existingNames, 'This name is already used'),
            })}
            onSubmit={(values: CopyTemplateFormValues) =>
                makeTimeineCopy(values.newName)
            }>
            <div className="d-flex flex-column">
                <div className="form-group row">
                    <div className="col-md-3 d-flex mt-2">
                        <label className="copy-name-label" htmlFor="newName">
                            Copy Name:
                        </label>
                    </div>
                    <div className="col-md-9">
                        <Field
                            type="text"
                            name="newName"
                            id="newName"
                            placeholder="Enter group name"
                            className="form-control"
                        />
                    </div>
                </div>
                <div className="mt-auto d-flex justify-content-end">
                    <button
                        type="button"
                        className="btn btn-cancel"
                        onClick={props.onClose}>
                        Cancel
                    </button>
                    <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={isLoading}>
                        Copy Template
                    </button>
                </div>
            </div>
        </Form>
    );

    const renderLoader = () => {
        return (
            <div className="loader-container" style={{ height: '10vw' }}>
                <div className="loader-container-inner">
                    <div className="text-center">
                        <Loader active={isLoading} type="ball-scale-multiple" />
                    </div>
                    <h6 className="mt-5">Adding new corporate group...</h6>
                </div>
            </div>
        );
    };

    return (
        <div>
            <Rodal
                visible={props.isVisible}
                animation="slideTop"
                onClose={props.onClose}
                showMask={false}
                width={600}
                closeOnEsc
                className="modal-copy-timeline-popup"
                center>
                <div className="modal-header">
                    <h5>Timeline Template Copy</h5>
                </div>
                <div className="modal-body">
                    {isLoading ? renderLoader() : renderForm()}
                </div>
            </Rodal>
        </div>
    );
};

export default TimelineCopyModal;
