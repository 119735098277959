import React, { Fragment, useEffect, useState } from 'react';
import { DataGrid, GridColDef, GridOverlay } from '@mui/x-data-grid';
import { Box, Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faEye,
    faPenToSquare,
    faTrashCan
} from '@fortawesome/free-solid-svg-icons';
import './TimelineTable.scss';
import { TimelineEvent } from '../Model/TimelineEvent';
import { STAGE_ITEMS, PARTY_ITEMS } from '../Constants/dropdownItems';

interface TimelineTableProps {
    rows: TimelineEvent[];
    handleRemoveRow: (id: number) => void;
}

const TimelineTable = ({ rows, handleRemoveRow} : TimelineTableProps) => {

    const [eyeActionIsDisabled, setEyeActionIsDisabled] = useState(true);
    const [noteActionIsDisabled, setNoteActionIsDisabled] = useState(true);

    const getStageById = (id: number) => STAGE_ITEMS.find(item => item.id === id);

    const getStageClassName = (id: number) => {
        const stage = getStageById(id);
        return stage ? stage.code : '';
    };

    // const handleAddDependency = (currentRowId: number) => {
    //     const dependencyIndex = parseInt(prompt('Enter the index of the row to depend on:'), 10);

    //     if (!isNaN(dependencyIndex)) {
    //         setRows((prevRows) => {
    //             const currentRowIndex = prevRows.findIndex((row) => row.id === currentRowId);
    //             const targetRowIndex = prevRows.findIndex((row) => row.index === dependencyIndex);

    //             if (targetRowIndex !== -1 && currentRowIndex !== -1) {
    //             const updatedRows = [...prevRows];

    //             const [movedRow] = updatedRows.splice(currentRowIndex, 1);

    //             updatedRows.splice(targetRowIndex + 1, 0, movedRow);

    //             return updatedRows.map((row, idx) => ({
    //                 ...row,
    //                 index: idx + 1,
    //             }));
    //             }

    //             alert('Invalid index! Please try again.');
    //             return prevRows;
    //         });
    //     }
    // };

    const rootStyles = STAGE_ITEMS.reduce((styles, stage) => {
        if (stage.code) {
            styles[`& .${stage.code}`] = {
                backgroundColor: stage.color,
                display: 'flex',
                justifyContent: 'center',
            };
        }
        return styles;
    }, {} as Record<string, React.CSSProperties>); 
      
    const additionalStyles = {
        border: 'none',
        '.MuiDataGrid-row': {
            borderBottom: 'none',
            '&:nth-of-type(odd)': {
                backgroundColor: '#f9f9f9',
            },
            '&:nth-of-type(even)': {
                backgroundColor: '#ffffff',
            },
        },
        '.MuiDataGrid-columnHeader:focus': {
            outline: 'none',
        },
        '.MuiDataGrid-cell:focus': {
            outline: 'none',
        },
    };

    const finalStyles = { ...rootStyles, ...additionalStyles };

    const columns: GridColDef[] = [
    {
        field: 'index',
        headerName: 'Index',
        headerClassName: 'text-bold',
        width: 80,
        renderCell: (params: any) => params.api.getAllRowIds().indexOf(params.id) + 1,
    },
    {
        // todo: this field should be removed when the table behavior will be finished
        // Currently it was added to manage data clearly
        field: 'id',
        headerName: 'Id',
        headerClassName: 'text-bold',
        width: 80,
        renderCell: (params: any) => params.row.id,
    },
    { 
        field: 'stage', 
        headerName: 'Event',
        width: 100,
        headerAlign: 'center',
        renderCell: (params: any) => {
            const stageId = params.value;
            const stage = STAGE_ITEMS.find(item => item.id === stageId);
            return stage ? stage.code : 'N/A'
        },
        cellClassName: (params: any) => {
            const stageId = params.value;
            const stage = STAGE_ITEMS.find(item => item.id === stageId);
            return stage ? `${stage.code} center-align` : 'center-align';
        },
    },
    { 
        field: 'step',
        headerName: 'Step',
        flex: 1,
    },
    { 
        field: 'party',
        headerName: 'Party', 
        width: 100,
        renderCell: (params: any) => {
            const partyId = params.value;
            const party = PARTY_ITEMS.find(item => item.id === partyId);
            return party ? party.name : '-';
        }
    },
    {
        field: 'dependencies',
        headerName: 'Dependencies',
        flex: 1,
        renderCell: (params: any) => (
            <Box>
                <div
                    className="btn-link"
                    // onClick={() => handleAddDependency(params.row.id)}
                >
                    <span className="cursor-pointer">
                        <u>
                            Add dependency
                        </u>
                    </span>
                </div>   
            </Box>
        ),
    },
    { field: 'duration', headerName: 'Duration (Days)', width: 150 },
    { field: 'startDay', headerName: 'Start Day', width: 120 },
    { field: 'endDay', headerName: 'End Day', width: 120 },
    {
        field: 'linkedAction',
        headerName: 'Linked Action',
        width: 150,
        renderCell: (params: any) => (
            <Box>
                <div className="btn-link">
                    <span className="cursor-pointer">
                        <u>
                            Add Action
                        </u>
                    </span>
                </div>
            </Box>
        ),
    },
    {
        field: 'action',
        headerName: 'Actions',
        width: 100,
        renderCell: (params: any) => (
                <div className='action-cell'>
                    <div className="d-flex justify-content-between align-items-center">
                        <FontAwesomeIcon
                            className={`${eyeActionIsDisabled ? 'text-muted' : 'cursor-pointer'}`}
                            onClick={() => !eyeActionIsDisabled && (console.log(`Eye clicked for row ID: ${params.id}`))}
                            size="lg" icon={faEye}
                        />
                        <FontAwesomeIcon
                            className={`${noteActionIsDisabled ? 'text-muted' : 'cursor-pointer'}`}
                            onClick={() => !noteActionIsDisabled && (console.log(`Note clicked for row ID: ${params.id}`))}
                            size="lg" icon={faPenToSquare}
                        />
                        <FontAwesomeIcon
                            className="cursor-pointer"
                            onClick={() => handleRemoveRow(params.row.id)}
                            size="lg" icon={faTrashCan}
                        />
                    </div>
                </div>
           
        ),
    },
  ];

    return (
        <Box style={{ minHeight: '400px', maxHeight: '1000px', width: '100%', overflow: 'scroll' }}>
            <DataGrid
                disableColumnFilter
                disableColumnSorting
                disableRowSelectionOnClick
                disableColumnSelector
                disableColumnMenu
                disableDensitySelector
                rows={rows.map((row, index) => ({ ...row, rowIndex: index}))}
                getRowId={(row) => row.id}
                columns={columns}
                sx={finalStyles}
            />
        </Box>
    )
}

export default TimelineTable;