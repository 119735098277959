import React, { useState, useEffect } from 'react';
import { Input } from 'reactstrap';
import { SizeProp } from '@fortawesome/fontawesome';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition as IDSvg } from '@fortawesome/free-regular-svg-icons';
import {
    faXmark,
    faCheck,
    faPenToSquare,
    IconDefinition as IDSolid,
} from '@fortawesome/free-solid-svg-icons';
import './EditableName.scss';

interface EditableNameProps {
    value: string;
    viewContent?: JSX.Element;
    editIcon?: IDSolid | IDSvg;
    editIconSize?: SizeProp;
    saveIcon?: IDSolid | IDSvg;
    saveIconSize?: SizeProp;
    cancelIcon?: IDSolid | IDSvg;
    cancelIconSize?: SizeProp;
    updateValue: (newValue: string) => Promise<boolean>;
    valueValidator?: (value: string) => boolean;
    defaultEditMode?: boolean;
    onEditModeChange?: (isEditing: boolean) => void;
    nameIsValid?: boolean;
}

const EditableName = (props: EditableNameProps) => {

    const [editorState, setEditorState] = useState<'view' | 'edit'>(
        props.defaultEditMode ? 'edit' : 'view'
    );
    const [hasError, setHasError] = useState(false);
    const [fieldValue, setFieldValue] = useState<string>(props.value);

    useEffect(() => {
        setFieldValue(props.value);
    }, [props.value]);

    useEffect(() => {
        if (props.onEditModeChange) {
            props.onEditModeChange(editorState === 'edit');
        }
    }, [editorState]);

    const validateCellValue = props.valueValidator
        ? props.valueValidator
        : (name: string) => String(name).trim().length > 0;

    const renderEditor = () => (
        <div className="editable-name-editor">
            <Input
                className="mb-2 mb-lg-0"
                invalid={hasError}
                value={fieldValue}
                onChange={(event) => {
                    const valid = validateCellValue(event.target.value);
                    setHasError(!valid);
                    setFieldValue(event.target.value);
                }}></Input>

            <FontAwesomeIcon
                className="mb-2 mb-lg-0"
                icon={(props.cancelIcon ?? faXmark) as any}
                size={props.cancelIconSize ?? 'lg'}
                onClick={() => {
                    if (fieldValue !== props.value || props.nameIsValid) {
                        setFieldValue(props.value);
                    }
                    setEditorState('view');
                }}
            />

            <FontAwesomeIcon
                className="mb-2 mb-lg-0"
                icon={(props.saveIcon ?? faCheck) as any}
                size={props.saveIconSize ?? 'lg'}
                onClick={async () => {
                    if (!hasError) {
                        const doesNameExist = await props.updateValue(fieldValue);
                        if (!doesNameExist)
                            setEditorState('view');
                    }
                }}
            />
        </div>
    );

    const renderContent = () => (
        <div 
            className="editable-name-view" 
            style={{ cursor: 'pointer' }} 
            onClick={() => setEditorState('edit')}
        >
            {props.viewContent ? (
                <div>{props.viewContent}</div>
            ) : (
                <div>{props.value}</div>
            )}
        </div>
    );

    const render = () => {
        switch (editorState) {
            case 'edit':
                return renderEditor();
            default:
                return renderContent();
        }
    };

    return (
        <div>
            {render()}
        </div>
    )
}

export default EditableName