import { symbol } from "prop-types";

export const STAGE_ITEMS = [
    { id: 0, code: 'A', name: 'Engage Buyers', color: '#fbe2d5' },
    { id: 1, code: 'B', name: 'NDA & Process Documents Creation', color: '#f7c7ac' },
    { id: 2, code: 'C', name: 'Summary Presentation to Buyers', color: '#ebddff' },
    { id: 3, code: 'D', name: 'Detailed Financial Model to Buyers', color: '#dac1ff' },
    { id: 4, code: 'E', name: 'Buyer Meetings', color: '#c0e6f5' },
    { id: 5, code: 'F', name: 'LOI Submissions, Analysis & Final Decision', color: '#83cceb' },
];

export const PARTY_ITEMS = [
    { id: 0, name: 'VetValue' },
    { id: 1, name: 'Seller' },
    { id: 2, name: 'Buyer' }
];